<template>
  <transition name="modal">
    <div class="modal-mask" v-on:click="$emit('close')">
      <div class="modal-wrapper modal-midi submitProfileModal" v-on:click.stop>
        <div class="modal-container">
          <div class="modal-header">
            <div class="modal-header-icon">
              <icon name="user" scale="2"></icon>
            </div>
            <div class="modal-header-title">Активация профиля</div>
            <div class="modal-close" @click="$emit('close')">
              <icon name="times"></icon>
            </div>
          </div>
          <div class="modal-body">
            <div class="successSubmit" v-if="!isSuccess">
              <p>Активация учетной записи</p>
              <p v-if="isError">
                При активации учетной записи возникла ошибка!
                <br />
                Возможно ссылка активации устарела
              </p>
            </div>
            <div class="successSubmit" v-if="isSuccess">
              <div>Учетная запись успешно активирована!</div>
              <br />
              <router-link :to="{ name: 'signin' }" class="goAuth">Перейти к авторизации</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "SignInModal",
  data() {
    return {
      endPoint: "/auth/submit",
      submitId: false,
      submitToken: false,
      isSuccess: false,
      isError: false,
    };
  },
  methods: {
    submitProfile() {
      let data = {
        id: this.submitId,
        token: this.submitToken,
      };
      this.$http.post(this.endPoint, data).then((response) => {
        if (response.data.success) {
          this.isSuccess = true;
          setTimeout(() => {
            this.$emit("close");
            if (response.data.token) {
              this.$store.dispatch("signin", response.data.token);
              this.$router.push({ name: "profile" });
            } else {
              this.$router.push({ name: "signin" });
            }
          }, 2000);
        } else {
          this.isError = true;
          console.log(response.data);
        }
      });
    },
  },
  created() {
    this.submitId = this.$route.params.id;
    this.submitToken = this.$route.params.token;
    this.submitProfile();
  },
};
</script>

<style lang="sass">
@import "./modalStyle.sass"
</style>

<style lang="sass" scoped>
.submitProfileModal
	background: #0072bc

.successSubmit
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center
	color: #fff
	padding: 2rem 1rem
	text-align: center
	min-height: 200px

.goAuth
	display: block
	color: #fff
	margin-top: 1rem
	text-decoration: underline
</style>
