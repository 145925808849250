export default {
  landingSections: {
    headSection: {
      ru:
        "Специализированный ресурс DENTABULA является высокотехнологичным продуктом для работы специалистов в области стоматологии. Сайт содержит функционал необходимый для работы врачей стоматологов, зубных техников, а так же для обучения студентов и специалистов в стоматологической сфере. ",
    },
    stlViewerTitle: {
      ru: "3D VIEWER: просмотр 3D моделей формата STL",
    },
    stlViewerText: {
      ru:
        "Позволяет быстро и удобно просматривать 3D модели формата STL с Вашего компьютера, или запоминающего устройства. Визуализация моделей происходит прямо на сайте, и не требует установки дополнительного ПО. 3D VIEWER дает возможность загружать для просмотра одновременно несколько моделей. Функционал позволяет менять прозрачность и цвет всех компонентов сцены.",
    },
    stlViewerBtn: {
      ru: "Открыть для просмотра 3D моделей",
    },
    visualizationTitle: {
      ru: "3D визуализации",
    },
    visualizationText: {
      ru:
        "3D визуализации позволяют наглядно демонстрировать разного рода стоматологические процедуры, манипуляции, аномалии, результаты лечения, а так же виды протезирования и реставраций. Данный функционал незаменим для работы с пациентами, так как он с высокой реалистичностью и детализацией демонстрирует сложные для понимания стандартного обывателя объекты.",
    },
    visualizationBtn: {
      ru: "Открыть 3D визуализации",
    },
    library3dTitle: {
      ru: "3D библиотека",
    },
    library3dText: {
      ru:
        "- Цифровая библиотека фасонов зубов для моделирования реставрации и дизайна улыбки <br/> - Анатомия черепа в 3D <br/> - 3D файлы для изготовления ортодонтических систем <br/> - Все стоматологические файлы",
    },
    library3dBtn: {
      ru: "Открыть 3D библиотеки",
    },
    photoBankTitle: {
      ru: "Фото банк Dental photos",
    },
    photoBankText: {
      ru:
        "Фото банк содержит фотографии на стоматологическую тематику в хорошем качестве. Данные файлы можно использовать для создания демонстрационных слайдов, полиграфической продукции, а так же вэб разработок.",
    },
    photoBankBtn: {
      ru: "Открыть фото банк",
    },
    demoModelsTitle: {
      ru: "Обучающие и демонстрационные 3D модели",
    },
    demoModelsText: {
      ru:
        " 3D Демо модели от Pogarskiy Design были разработаны для конструирования дентальных моделей с разнообразными дефектами, которые можно использовать для обучения и создания демонстрационных моделей. Демо модели представлены в виде набора файлов в формате STL, в котором присутствуют основания моделей верхней и нижней челюсти и съемные элементы с зубами.",
    },
    demoModelsBtn: {
      ru: "Создать демо модель",
    },
    showMoreSection: {
      ru: "Функционал сайта постоянно обновляется. <br/> Следите за новыми обновлениями и контентом.",
    },
  },
};
