<template>
  <transition name="modal">
    <div class="modal-mask" v-on:click="$emit('close')">
      <div class="modal-wrapper modal-midi resetPasswordModal" v-on:click.stop>
        <div class="modal-container">
          <div class="modal-header">
            <div class="modal-header-icon">
              <icon name="user" scale="2"></icon>
            </div>
            <div class="modal-header-title">Смена пароля</div>
            <div class="modal-close" @click="$emit('close')">
              <icon name="times"></icon>
            </div>
          </div>
          <div class="modal-body">
            <form id="resetPasswordForm" class="inputForm" v-on:submit.prevent.stop="resetPassword" v-if="!isSuccess">
              <p class="otherError" v-if="errors.other">{{ errors.other }}</p>
              <div class="inputBlock" :class="{ error: errors.password }">
                <icon name="key" scale="1.3" class="inputIcon"></icon>
                <div class="inputComponent">
                  <input
                    type="password"
                    name="password"
                    v-model="resetData.password"
                    class="inputField"
                    placeholder="Введите пароль"
                    autocomplete="off"
                  />
                  <p class="errorField" v-if="errors.password">{{ errors.password }}</p>
                </div>
              </div>
              <div class="inputBlock" :class="{ error: errors.passwordRetry }">
                <icon name="key" scale="1.3" class="inputIcon"></icon>
                <div class="inputComponent">
                  <input
                    type="password"
                    name="passwordRetry"
                    v-model="resetData.passwordRetry"
                    class="inputField"
                    placeholder="Введите пароль повторно"
                    autocomplete="off"
                  />
                  <p class="errorField" v-if="errors.passwordRetry">{{ errors.passwordRetry }}</p>
                </div>
              </div>
              <button type="submit" class="btn btnResetPassword">Сменить пароль</button>
            </form>
            <div class="successReset" v-if="isSuccess">
              <div>Пароль успешно изменен!</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ResetPassword",
  props: ["resetId", "resetToken"],
  data() {
    return {
      endPoint: "/auth/reset_password",
      isSuccess: false,
      resetData: {
        password: "",
        passwordRetry: "",
      },
      errors: {
        password: "",
        passwordRetry: "",
        other: "",
      },
      errorsList: {
        "0": "Данное поле не должно быть пустым",
        "1": "Токен восстановления пароля не валидный",
        "2": "Учетная запись не подтверждена",
        "3":
          "Ваш пароль должен содержать не менее 6 и не более 16 символов, состоять из латинских букв и минимум 1-й цифры",
        "4": "Введенные Вами пароли не совпадают",
      },
    };
  },
  methods: {
    resetPassword() {
      this.errors.password = !this.resetData.password ? this.errorsList["0"] : "";
      this.errors.passwordRetry = !this.resetData.passwordRetry ? this.errorsList["0"] : "";
      this.errors.other = "";

      if (this.resetData.password && this.resetData.passwordRetry) {
        let data = {
          userId: this.resetId,
          submitToken: this.resetToken,
          password: this.resetData.password,
          passwordRetry: this.resetData.passwordRetry,
        };

        this.$http.post(this.endPoint, data).then((response) => {
          if (response.data.success) {
            this.isSuccess = true;
            setTimeout(() => {
              this.$emit("close");
              if (response.data.token) {
                this.$store.dispatch("signin", response.data.token);
                this.$router.push({ name: "profile" });
              } else {
                this.$router.push({ name: "signin" });
              }
            }, 2000);
          } else if (response.data.info) {
            this.errors.password = response.data.info.password ? this.errorsList[response.data.info.password] : "";
            this.errors.passwordRetry = response.data.info.passwordRetry
              ? this.errorsList[response.data.info.passwordRetry]
              : "";
            this.errors.other = response.data.info.other ? this.errorsList[response.data.info.other] : "";
          } else {
            console.log(response);
          }
        });
      }
    },
  },
};
</script>

<style lang="sass">
@import "./modalStyle.sass"
</style>

<style lang="sass" scoped>
.resetPasswordModal
    background: #0072bc
.successReset
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center
	color: #fff
	padding: 2rem 1rem
	text-align: center
	min-height: 200px
#resetPasswordForm
	padding: 1rem 4rem 2rem
	@media screen and (max-width: 426px)
		padding: 1rem 1rem 2rem
	& .otherError
		color: #fff
		padding: 0.5rem 0
		text-align: center
	& .btnResetPassword
        display: flex
        align-items: center
        justify-content: center
        width: 100%
        height: 45px
        font-size: 1.1rem
        color: #0072bc
        background: #fff
        border: 1px solid transparent
        margin-bottom: 1rem
        cursor: pointer
</style>
