<template>
  <div id="landing">
    <mainMenu :isSignin="isSignin" :isSignup="isSignup"></mainMenu>
    <section id="landingHeadSection">
      <div class="wrapper sectionWrapper">
        <img src="./../../assets/images/dentabula-logo.png" class="landingPageLogo" alt />
        <p class="sectionDescription headSection" v-html="translate.landingSections.headSection[currentLang]"></p>
      </div>
    </section>

    <div class="wrapper">
      <h3 class="sectionTitle" v-html="translate.landingSections.stlViewerTitle[currentLang]"></h3>
    </div>
    <section id="landingStlViewer">
      <div class="wrapper sectionWrapper">
        <p class="sectionDescription stlViewer" v-html="translate.landingSections.stlViewerText[currentLang]"></p>
        <router-link
          :to="{ name: 'stlViewer' }"
          class="landingBtn stlViewer"
          v-html="translate.landingSections.stlViewerBtn[currentLang]"
        ></router-link>
      </div>
    </section>

    <div class="wrapper">
      <h3 class="sectionTitle" v-html="translate.landingSections.visualizationTitle[currentLang]"></h3>
    </div>
    <section id="landingVisualization">
      <div class="wrapper sectionWrapper">
        <p
          class="sectionDescription visualization"
          v-html="translate.landingSections.visualizationText[currentLang]"
        ></p>
        <router-link
          :to="{ name: 'visualization' }"
          class="landingBtn visualization"
          v-html="translate.landingSections.visualizationBtn[currentLang]"
        ></router-link>
      </div>
    </section>

    <div class="wrapper">
      <h3 class="sectionTitle" v-html="translate.landingSections.library3dTitle[currentLang]"></h3>
    </div>
    <section id="landingLibrary3d">
      <div class="wrapper sectionWrapper">
        <p class="sectionDescription library3d" v-html="translate.landingSections.library3dText[currentLang]"></p>
        <router-link
          :to="{ name: 'library3d' }"
          class="landingBtn library3d"
          v-html="translate.landingSections.library3dBtn[currentLang]"
        ></router-link>
      </div>
    </section>
    <div class="wrapper">
      <h3 class="sectionTitle" v-html="translate.landingSections.photoBankTitle[currentLang]"></h3>
    </div>
    <section id="landingPhotoBank">
      <div class="wrapper sectionWrapper">
        <p class="sectionDescription photoBank">{{ translate.landingSections.photoBankText[currentLang] }}</p>
        <router-link
          :to="{ name: 'photoBank' }"
          class="landingBtn photoBank"
          v-html="translate.landingSections.photoBankBtn[currentLang]"
        ></router-link>
      </div>
    </section>
    <div class="wrapper">
      <h3 class="sectionTitle" v-html="translate.landingSections.demoModelsTitle[currentLang]"></h3>
    </div>
    <section id="landingDemoModels">
      <div class="wrapper sectionWrapper">
        <p class="sectionDescription demoModels" v-html="translate.landingSections.demoModelsText[currentLang]"></p>
        <router-link
          :to="{ name: 'demoModels' }"
          class="landingBtn demoModels"
          v-html="translate.landingSections.demoModelsBtn[currentLang]"
        ></router-link>
      </div>
    </section>
    <div class="wrapper">
      <h3 class="sectionTitle showMoreSection" v-html="translate.landingSections.showMoreSection[currentLang]"></h3>
    </div>
    <mainFooter></mainFooter>
    <submitProfileModal v-if="isSubmitProfile" @close="isSubmitProfile = false"></submitProfileModal>
    <resetPasswordModal
      v-if="isResetPassword"
      @close="isResetPassword = false"
      :resetId="$route.params.id"
      :resetToken="$route.params.token"
    ></resetPasswordModal>
  </div>
</template>

<script>
import mainMenu from "./../mainMenu/mainMenu.vue";
import mainFooter from "./../mainFooter/mainFooter.vue";
import submitProfileModal from "./../modals/submitProfileModal.vue";
import resetPasswordModal from "./../modals/resetPasswordModal.vue";
import translate from "./translate.js";

export default {
  name: "Landing",
  components: { mainMenu, mainFooter, submitProfileModal, resetPasswordModal },
  props: ["submit_profile", "reset_password", "isSignin", "isSignup"],
  data() {
    return {
      isSubmitProfile: false,
      isResetPassword: false,
      translate: translate,
    };
  },
  computed: {
    currentLang() {
      return this.$store.getters.currentLang;
    },
  },
  created() {
    this.isSubmitProfile = this.submit_profile ? true : false;
    this.isResetPassword = this.reset_password ? true : false;
  },
};
</script>

<style lang="sass">
#landing
	.wrapper
		display: flex
		max-width: 1024px
		margin: 0 auto
		// padding: 2rem 0.5rem
		&.sectionWrapper
			justify-content: space-around
	.sectionTitle
		font-family: 'Ropa Sans Pro SC Regular'
		font-size: 2.5rem
		color: #535253
		padding: 2rem 0
		font-weight: 500
		@media screen and (max-width: 425px)
			font-size: 1.6rem
	.landingBtn
		display: flex
		align-items: center
		justify-content: center
		width: 100%
		max-width: 350px
		font-size: 16px
		text-align: center
		padding: 1rem 0
		border: 1px solid transparent
		&.stlViewer
			color: #fff
			background: #00aeef
		&.visualization
			color: #fff
			background: #f7941d
		&.library3d
			color: #fff
			background: #a72b75
		&.photoBank
			color: #fff
			background: #ff0000
		&.demoModels
			color: #fff
			background: #ae2877


	.sectionDescription
		font-size: 18px
		width: 100%
		max-width: 470px
		line-height: 1.2
		text-shadow: 0px 0px 1px #535253
		@media screen and (max-width: 768px)
			font-size: 14px
		&.headSection
			color: #fcfcfe
		&.stlViewer
			color: #fff568
		&.visualization
			color: #040404
		&.library3d
			color: #ffffff
		&.photoBank
			color: #fefefe
		&.demoModels
			color: #ffffff

	#landingHeadSection
		display: flex
		min-height: 365px
		background: url("./../../assets/images/landingHeaderSectionBackground.png")
		background-size: cover
		background-position: center center
		& .landingPageLogo
			display: block
			width: 100%
			max-width: 420px
			height: auto
			max-height: 80px

	#landingStlViewer
		display: flex
		min-height: 365px
		background: url("./../../assets/images/landingStlViewerBackground.png")
		background-size: cover
		background-position: center center

	#landingVisualization
		display: flex
		min-height: 365px
		background: url("./../../assets/images/landingVisualizationBackground.png")
		background-size: cover
		background-position: center center

	#landingLibrary3d
		display: flex
		min-height: 365px
		background: url("./../../assets/images/landingLibrary3dBackground.png")
		background-size: cover
		background-position: center center

	#landingPhotoBank
		display: flex
		min-height: 270px
		background: url("./../../assets/images/landingPhotoBankBackground.png")
		background-size: cover
		background-position: center center

	#landingDemoModels
		display: flex
		min-height: 365px
		background: url("./../../assets/images/landingDemoModelsBackground.png")
		background-size: cover
		background-position: center center

	.showMoreSection
		text-align: center
</style>
